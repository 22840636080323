<template>
  <div class="columns">
    <div class="column">
      <a-select
        :manual-doc="userDoc"
        field="routes.ramsNodes.where.part"
        :label="$t('parts.ref.name  ')"
        options="parts"
        null-option
        @change="(value) => updateAndFetch('part', value, true)"
      />
    </div>
  </div>
</template>

<script>
import { keys } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import SimpleTaginput from '@/components/SimpleTaginput'

export default {
  components: {
    SimpleTaginput
  },
  computed: {
    ...mapGetters({
      parts: 'parts/items'
    }),
    userDoc () {
      return { module: 'user' }
    },
    where () {
      return this.user.routes.ramsNodes.where
    }
  },
  methods: {
    keys,
    async updateAndFetch (prop, value, fetch = false) {
      await this.$store.dispatch('user/update', {
        path: `routes.ramsNodes.where.${prop}`,
        value
      })
      if(fetch) {
        this.$store.dispatch('ramsNodes/fetchDocs')
      }
    },
    async updateNumber (value) {
      await this.updateAndFetch('part', null)
      await this.updateAndFetch('state', null)
      this.updateAndFetch('number_array', value, true)
    }
  }
}
</script>
